@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TCCC";
  src: url("./fonts/TCCC-UnityHeadline_GRE-Regular.ttf");
  font-weight: initial;
}

@font-face {
  font-family: "TCCC";
  src: url("./fonts/TCCC-UnityHeadline_GRE-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "TCCC";
  src: url("./fonts/TCCC-UnityHeadline_GRE-Black.ttf");
  font-weight: 900;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
